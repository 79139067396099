<template>
  <div class="user flex flex-col bg-yellow min-h-screen relative py-8">
    <p
      class="text-white absolute top-5 left-5 underline cursor-pointer"
      @click="$router.go(-1)"
    >
      ATRÁS
    </p>
    <div
      class="flex flex-col items-center justify-start self-center w-11/12"
      v-if="user"
    >
      <h2 class="font-bold text-2xl">{{ user.name }}</h2>
      <h3 class="font-bold text-xl" v-if="user.vip">(VIP)</h3>
    </div>

    <div
      class="grid grid-cols-3 w-full items-center justify-items-center text-center"
      v-if="user"
    >
      <p class="mb-0"><span class="font-bold">Nombre: </span>{{ user.name }}</p>
      <p class="mb-0">
        <span class="font-bold">Teléfono: </span>{{ user.phone }}
      </p>
      <p class="mb-0">
        <span class="font-bold">Correo: </span>{{ user.email }}
      </p>
      <p class="mb-0"><span class="font-bold">Ciudad: </span>{{ user.city }}</p>
      <p class="mb-0">
        <span class="font-bold">Compañia: </span>{{ company.name }}
      </p>
      <p class="mb-0">
        <span class="font-bold">Rol: </span
        >{{ user.role === "WORKER" ? "Dependiente" : "Dueño" }}
      </p>
      <p class="mb-0"><span class="font-bold">ID: </span>{{ user.uid }}</p>
      <p class="mb-0">
        <span class="font-bold">Puntos actuales: </span>{{ user.points }}
      </p>
      <p class="mb-0">
        <span class="font-bold">Puntos totales: </span>{{ user.totalPoints }}
      </p>
      <p class="mb-0">
        <span class="font-bold">Cédula: </span>{{ user?.cc || "No registra" }}
      </p>
      <p class="mb-0">
        <span class="font-bold">Registrado: </span
        >{{ new Date(user.createdAt).toLocaleDateString() }}
      </p>
      <p class="mb-0">
        <span class="font-bold">Región: </span>{{ user.region }}
      </p>
      <p class="mb-0">
        <span class="font-bold">Dirección: </span>{{ user.address }}
      </p>
      <p class="mb-0"><span class="font-bold">Nivel: </span>{{ user.level }}</p>
    </div>
    <!-------------------->

    <div
      v-if="modal"
      class="w-full min-h-full bg-black bg-opacity-80 fixed top-0 right-0 flex items-center justify-center"
    >
      <div
        class="bg-white py-2 px-4 font-bold absolute top-5 right-20 rounded-full cursor-pointer"
        @click="inactiveModal()"
      >
        X
      </div>

      <form
        v-if="!confirmPoints"
        class="bg-white grid grid-cols-3 gap-4 rounded-xl py-10 px-20"
        @submit.prevent="sendPoints()"
      >
        <label for="category" class="text-xl">Categoría: </label>
        <select class="col-span-2" type="select" id="category" required>
          <option>-</option>
          <option value="Ajuste de periodo">Ajuste de periodo</option>
          <option value="Lanzamiento Temptations">
            Lanzamiento Temptations
          </option>
          <option value="Foto campaña Temptations">
            Foto campaña Temptations
          </option>
          <option value="Reto P10 manual">Reto P10 manual</option>
          <option value="Cumplimiento P12 manual">
            Cumplimiento P12 manual
          </option>
          <option value="Estrella Navideña">Estrella Navideña</option>
        </select>
        <label for="points" class="text-xl">Puntos: </label>
        <input
          class="input_modal col-span-2"
          type="number"
          min="0"
          id="points"
          required
        />
        <label for="period" class="text-xl">Periodo: </label>
        <select
          class="input_modal col-span-2"
          type="select"
          id="period"
          required
        >
          <option>-</option>
          <option value="p01">p01</option>
          <option value="p02">p02</option>
          <option value="p03">p03</option>
          <option value="p04">p04</option>
          <option value="p05">p05</option>
          <option value="p06">p06</option>
          <option value="p07">p07</option>
          <option value="p08">p08</option>
          <option value="p09">p09</option>
          <option value="p10">p10</option>
          <option value="p11">p11</option>
          <option value="p12">p12</option>
          <option value="p13">p13</option>
        </select>
        <label for="concept" class="text-xl col-span-3">Concepto: </label>
        <textarea
          class="col-span-3"
          name="textarea"
          rows="5"
          cols="40"
          id="concept"
          required
        />
        <div class="col-span-3 pt-3 flex justify-center">
          <button type="submit" class="bg-yellow py-1 px-2 rounded-xl">
            Registra Puntos
          </button>
        </div>
      </form>
      <!--CONFIRMAR INFORMACIÓN-->
      <div
        v-if="confirmPoints"
        class="max-w-xl bg-yellow-400 grid grid-cols-3 gap-4 rounded-xl py-10 px-20"
      >
        <span class="text-xl text-red-600 font-bold">Categoría: </span>
        <span class="col-span-2">{{ regCategory }}</span>
        <span class="text-xl text-red-600 font-bold">Puntos: </span>
        <span class="col-span-2">{{ regPoints }}</span>
        <span class="text-xl text-red-600 font-bold">Periodo: </span>
        <span class="col-span-2">{{ regPeriod }}</span>
        <p class="text-xl text-red-600 font-bold">Concepto:</p>
        <span class="col-span-2">{{ regConcept }}</span>
        <div class="col-span-3 pt-3 flex justify-center">
          <button
            class="bg-red-500 text-white py-1 px-10 rounded-xl"
            @click="regInput()"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
    <div class="w-full p-10 flex items-center justify-around gap-5">
      <button
        v-if="user.role == 'WORKER'"
        class="bg-black text-white font-bold text-xl cursor-pointer px-5 py-2 rounded-xl"
        @click="activeModal()"
      >
        Agregar Puntos
      </button>

      <button
        v-if="user.role == 'WORKER' && buttonAcceptChallengep10 == true"
        class="bg-black text-white font-bold text-xl cursor-pointer ml-10 my-10 py-2 rounded-xl px-5"
        @click="acceptChallenge()"
      >
        Aceptar parte 3 Reto P10
      </button>
      <div
        v-if="user.role == 'WORKER' && buttonAcceptChallengep10 == false"
        class="ml-10 bg-red-400 text-xl font-bold py-2 px-2 border border-black"
      >
        La parte 3 del reto P10 fue aceptada
      </div>
      <!-- <button 
        v-if="user.role=='WORKER' && !isChallengeP13Part1Presented"
        class="bg-green-600 text-white font-bold text-xl cursor-pointer px-5 py-2 rounded-xl"
        @click="() => confirmCS = true">
        Aceptar Parte 1 Reto P13 <br> Estrella Navideña
      </button>
      <div v-if="user.role=='WORKER' && isChallengeP13Part1Presented" class="ml-10 bg-green-600 text-xl text-white font-bold py-2 px-2 border border-black">
        El Reto P13 (Parte 1) fue aceptado
      </div> -->
    </div>
    <!-------------------->

    <div class="flex flex-col items-start w-11/12 mt-4 self-center">
      <h2 class="text-xl font-bold mb-2">Puntos</h2>
      <v-data-table
        :headers="headersPoints"
        :items="points"
        :items-per-page="10"
        class="w-full"
        :loading="loadingDataPoints"
        loading-text="Cargando puntaje"
      >
      </v-data-table>
    </div>

    <!-- <form
      class="flex items-center w-11/12 self-center mt-4"
      @submit.prevent="setPointLocal"
      v-if="user && !(user.vip && user.role === 'OWNER')"
    >
      <label
        for="add-point"
        class="font-bold text-xl mr-2"
      >Agregar puntos</label>
      <select
        id="add-point"
        v-model="optionAddPoint"
        class="bg-white rounded-md py-1 px-4 mr-4"
        required
      >
        <option value="">-- Escoja una opción --</option>
        <option value="Charlas y cursos">Charlas y cursos</option>
        <option
          value="Reto 1"
          v-if="!user.vip && user.role === 'OWNER' "
        >Reto 1</option>
        <option
          value="Registro Webinar ¿Alimentar gatos es cosa de locos?"
          v-if="!user.vip || (user.vip && user.role === 'WORKER')"
        >Registro Webinar ¿Alimentar gatos es cosa de locos?</option>
        <option
          value="Asistencia Webinar ¿Alimentar gatos es cosa de locos?"
          v-if="!user.vip || (user.vip && user.role === 'WORKER')"
        >Asistencia Webinar ¿Alimentar gatos es cosa de locos?</option>
        <option
          value="Reto 2"
          v-if="!user.vip && user.role === 'OWNER' "
        >Reto 2</option>
      </select>
      <input type="number" v-model="challengeOneOwnerPoints" placeholder="Puntos (SOLO NÚMEROS)" v-if="optionAddPoint === 'Reto 1'" class="bg-white rounded-md py-1 mr-4 px-4">
      <select
        v-model="selectedConference"
        class="bg-white rounded-md py-1 px-4 mr-4"
        v-if="optionAddPoint === 'Charlas y cursos'"
        required
      >
        <option value="">-- Escoja una opción --</option>
        <option
          v-for="conference in conferences"
          :key="conference.id"
          :value="conference.title"
        >{{conference.title}}</option>
      </select>
      <select
        v-model="selectedChallengeTwo"
        class="bg-white rounded-md py-1 px-4 mr-4"
        v-if="optionAddPoint === 'Reto 2'"
        required
      >
        <option value="">-- Escoja una opción --</option>
        <option
          v-for="(challenge, i) in company.challengeTwo"
          :key="i"
          :value="challenge.points"
        >{{`Más de ${challenge.money} - ${challenge.points} puntos`}}</option>
      </select>
      <button class="bg-white text-yellow uppercase rounded-lg py-1 px-2 font-bold">Agregar</button>
    </form>

    <div
      class="flex flex-col w-11/12 self-center my-8"
      v-if="user && user.role === 'WORKER'"
    >
      <label
        for="add-point"
        class="font-bold text-xl mr-2"
      >Retos Desafío Club Mars</label>
      <v-data-table
        :headers="headersDcm"
        :items="dcmChallenges"
        :items-per-page="10"
        class="w-full"
        :loading="loadingData"
        loading-text="Cargando retos"
      >
      </v-data-table>
    </div>

    <div
      class="flex flex-col w-11/12 self-center my-8"
      v-if="user && user.role === 'WORKER'"
    >
      <label
        for="add-point"
        class="font-bold text-xl mr-2"
      >Retos</label>
      <v-data-table
        :headers="headers"
        :items="challenges"
        :items-per-page="10"
        class="w-full"
        :loading="loadingData"
        loading-text="Cargando retos"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ new Date(item.createdAt).toLocaleDateString() }}
        </template>

        <template v-slot:[`item.imgUrl`]="{ item }">
          <a
            :href="item.imgUrl"
            target="_blank"
          >Ver</a>
        </template>

        <template v-slot:[`item.accept`]="{ item }">
          <button
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="setChallengePoint(item)"
          >Aceptar</button>
        </template>

        <template v-slot:[`item.reject`]="{ item }">
          <button
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="rejectChallengePoint(item)"
          >Rechazar</button>
        </template>
      </v-data-table>
    </div> -->

    <v-alert
      class="alert absolute bottom-5 left-1/2 transform -translate-x-1/2 self-start"
      dense
      color="#F55252"
      elevation="4"
      max-width="90%"
      v-model="showAlert"
      type="error"
      dismissible
      >{{ errorMessage }}</v-alert
    >

    <div
      v-if="confirm"
      class="absolute top-0 left-0 bg-black bg-opacity-60 w-full h-full flex justify-center"
    >
      <div
        class="absolute top-10 right-10 bg-white py-2 px-4 rounded-full font-bold cursor-pointer"
        @click="closeModal()"
      >
        X
      </div>
      <div class="bg-white w-1/2 h-[35%] mt-44 rounded-3xl py-10 px-10">
        <p class="text-5xl font-bold">Confirmar Reto</p>
        <p class="text-2xl font-bold mt-10">
          Nombre:
          <span class="text-2xl font-light text-red-700 uppercase">{{
            user.name
          }}</span>
        </p>
        <p class="text-2xl font-bold">
          Email:
          <span class="text-2xl font-light text-red-700">{{ user.email }}</span>
        </p>
        <p class="text-2xl font-bold">
          Compañía:
          <span class="text-base font-light text-red-700">{{
            company.name
          }}</span>
        </p>
        <div class="w-full flex justify-end">
          <button
            class="text-xl font-bold bg-yellow px-4 py-2 rounded-xl"
            @click="confirmChallenge()"
          >
            Aceptar Reto
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="confirmCS"
      class="absolute top-0 left-0 bg-black bg-opacity-60 w-full min-h-screen flex items-center justify-center"
    >
      <div class="bg-white w-1/2 rounded-3xl py-10 px-10 relative">
        <div
          class="absolute -top-10 -right-10 bg-white py-2 px-4 rounded-full font-bold cursor-pointer"
          @click="() => (confirmCS = false)"
        >
          X
        </div>
        <p class="text-5xl font-bold">Confirmar Reto</p>
        <p class="text-2xl font-bold mt-10">
          Nombre:
          <span class="text-2xl font-light text-red-700 uppercase">{{
            user.name
          }}</span>
        </p>
        <p class="text-2xl font-bold">
          Email:
          <span class="text-2xl font-light text-red-700">{{ user.email }}</span>
        </p>
        <p class="text-2xl font-bold">
          Compañía:
          <span class="text-base font-light text-red-700">{{
            company.name
          }}</span>
        </p>
        <div class="w-full flex justify-end">
          <button
            class="text-xl text-white font-bold px-4 py-2 rounded-xl bg-green-600"
            @click="confirmCSChallenge()"
          >
            Aceptar Reto
          </button>
        </div>
      </div>
    </div>

    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "../components/Loader.vue";
export default {
  components: { Loader },
  data() {
    return {
      optionAddPoint: "",
      modal: false,
      confirm: false,
      regPoints: 0,
      regConcept: "",
      regPeriod: "",
      regCategory: "",
      buttonAcceptChallengep10: null,
      challengeP13: null,
      isChallengeP13Part1Presented: false,
      headers: [
        {
          text: "Id del reto",
          align: "start",
          value: "challengeId",
        },
        { text: "Puntos", value: "points" },
        { text: "Fecha", value: "createdAt" },
        { text: "Ver imagen", value: "imgUrl" },
        { text: "Aceptar", value: "accept" },
        { text: "Rechazar", value: "reject" },
      ],
      challenges: [],
      loadingData: true,
      isLoading: true,
      user: null,
      headersPoints: [
        {
          text: "Categoría",
          align: "start",
          value: "category",
        },
        { text: "Puntos", value: "points" },
        { text: "Periodo", value: "period" },
        { text: "Referencia", value: "ref" },
      ],
      points: [],
      loadingDataPoints: true,
      conferences: [],
      selectedConference: "",
      showAlert: false,
      errorMessage: "",
      currentPeriod: "",
      company: null,
      selectedChallengeTwo: "",
      challengeOneOwnerPoints: 0,
      confirm: false,
      confirmCS: false,
      confirmPoints: false,
      userUid: "",
      dcmChallenges: [],
      headersDcm: [
        {
          text: "Id del reto",
          align: "start",
          value: "challengeId",
        },
        { text: "Fecha", value: "created" },
      ],
    };
  },
  async mounted() {
    const res = await this["user/searchUser"]({
      searchTerm: "id",
      search: this.$route.params.uid,
    });

    if (res.success) {
      this.user = res.user;
    }

    const res3 = await this["user/getUserPoints"](this.$route.params.uid);
    if (res3.success) {
      this.points = res3.points;
    }

    const res4 = await this["user/getUserChallenges"](this.$route.params.uid);
    if (res4.success) {
      this.challenges = res4.challenges;
    }

    const res5 = await this["user/getConferences"]();
    if (res5.success) {
      this.conferences = res5.conferences;
    }

    const res6 = await this["user/getCurrentPeriod"]();
    if (res6.success) {
      this.currentPeriod = res6.currentPeriod;
    }

    const res7 = await this["companies/getCompanyById"](res.user.companyId);
    if (res7.success) {
      this.company = res7.data;
    }

    const res8 = await this["challenges/get2024P10Challenge"](this.user.uid);
    if (res8.accepted) {
      this.buttonAcceptChallengep10 = false;
    } else {
      this.buttonAcceptChallengep10 = true;
    }

    this.loadingData = false;
    this.loadingDataPoints = false;
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      "points/setPointsManual",
      "user/searchUser",
      "user/getUserPoints",
      "user/getUserChallenges",
      "user/getConferences",
      "user/getCurrentPeriod",
      "user/setPoint",
      "user/setChallengePoint",
      "user/rejectChallengePoint",
      "companies/getCompanyById",
      "challenges/setChristmasChallengeThreePoint",
      "challenges/getDCMChallengesP05",
      "challenges/acceptChallengeP06",
      "challenges/acceptChallengeP07",
      "challenges/acceptChallengeP10",
      "challenges/setChallengeP13Part1",
      "user/getUsers",
      "challenges/get2024P10Challenge",
      "challenges/getCSChallengesP13ById",
      "user/getUserDCMChallenges",
    ]),

    acceptChallenge() {
      this.confirm = true;
    },

    closeModal() {
      this.confirm = false;
    },

    async confirmChallenge() {
      this.isLoading = true;
      this.confirm = false;
      const res = await this["challenges/acceptChallengeP10"](this.user);
      this.isLoading = false;

      if (res.success) {
        this.buttonAcceptChallengep10 = false;
      } else {
        alert(res.message);
      }
    },

    async confirmCSChallenge() {
      this.isLoading = true;

      try {
        const action = this.challengeP13 === null ? "create" : "update";
        let obj;

        if (action === "create") {
          obj = {
            challengeId: "p13",
            companyId: this.user.companyId,
            companyName: this.user.companyName,
            createdAt: Date.now(),
            role: this.user.role,
            uid: this.user.uid,
            year: "2022",
            part_1: {
              reviewedAt: Date.now(),
              isCompleted: true,
              isReviewed: true,
              isValid: true,
            },
            isCompleted: false,
            isReviewed: false,
            isValid: false,
          };
        }

        if (action === "update") {
          obj = {
            part_1: {
              reviewedAt: Date.now(),
              isCompleted: true,
              isReviewed: true,
              isValid: true,
            },
            updatedAt: Date.now(),
            isCompleted: this.challengeP13.data.part_2
              ? this.challengeP13.data.part_2.isCompleted
                ? true
                : false
              : false,
            isReviewed: this.challengeP13.data.part_2
              ? this.challengeP13.data.part_2.isReviewed
                ? true
                : false
              : false,
            isValid: this.challengeP13.data.part_2
              ? this.challengeP13.data.part_2.isValid
                ? true
                : false
              : false,
          };
        }

        await this["challenges/setChallengeP13Part1"]({
          obj,
          action,
          challengeDocId: this.challengeP13?.id,
        });

        this.isChallengeP13Part1Presented = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.confirmCS = false;
        this.isLoading = false;
      }
    },

    activeModal() {
      this.modal = true;
    },

    async regInput() {
      this.isLoading = true;

      const data = {
        category: this.regCategory,
        companyId: this.user.companyId,
        points: this.regPoints,
        period: this.regPeriod,
        timeStamp: Date.now(),
        uid: this.user.uid,
        ref: this.regConcept,
      };

      await this["points/setPointsManual"](data);
      this.points.push(data);
      this.user.points += this.regPoints;
      this.user.totalPoints += this.regPoints;
      this.isLoading = false;
      this.inactiveModal();
    },

    inactiveModal() {
      this.modal = false;
      this.confirm = false;
      this.confirmPoints = false;
      this.regPoints = 0;
      this.regConcept = "";
      this.regCategory = "";
      this.regPeriod = "";
    },

    sendPoints() {
      this.regPoints = parseInt(document.getElementById("points").value);
      this.regPeriod = document.getElementById("period").value;
      this.regConcept = document.getElementById("concept").value;
      this.regCategory = document.getElementById("category").value;

      if (this.regCategory !== "-") {
        if (this.regPoints > 0) {
          if (this.regPeriod !== "-") {
            if (this.regConcept !== "") {
              this.confirmPoints = true;
            } else {
              window.alert(
                "Describa el concepto por el cual se asignan los puntos"
              );
            }
          } else {
            window.alert("Seleccione un Periodo");
          }
        } else {
          window.alert("El valor de los puntos debe ser mayor de Cero '0'");
        }
      } else {
        window.alert("Seleccione una Categoría");
      }
    },

    async setPointLocal() {
      this.isLoading = true;

      if (this.optionAddPoint === "Charlas y cursos") {
        const obj = {
          category: this.regCategory,
          companyId: this.user.companyId,
          points: 25,
          period: this.currentPeriod,
          ref: this.selectedConference,
          timeStamp: Date.now(),
          uid: this.user.uid,
          year: "2022",
        };

        await this["user/setPoint"](obj);

        this.user.points += 25;
        this.user.totalPoints += 25;
        this.points.push(obj);
        this.isLoading = false;
      } else if (this.optionAddPoint === "Reto 1") {
        const obj = {
          category: "Reto 1",
          companyId: this.user.companyId,
          points: parseInt(this.challengeOneOwnerPoints),
          period: "p06 - p07",
          ref: "Reto 1",
          timeStamp: Date.now(),
          uid: this.user.uid,
          year: "2022",
        };

        await this["user/setPoint"](obj);

        this.user.points += parseInt(this.challengeOneOwnerPoints);
        this.user.totalPoints += parseInt(this.challengeOneOwnerPoints);
        this.points.push(obj);
        this.isLoading = false;
      } else if (
        this.optionAddPoint ===
        "Registro Webinar ¿Alimentar gatos es cosa de locos?"
      ) {
        const obj = {
          category: this.optionAddPoint,
          companyId: this.user.companyId,
          points: 5,
          period: this.currentPeriod,
          ref: this.optionAddPoint,
          timeStamp: Date.now(),
          uid: this.user.uid,
          year: "2022",
        };

        await this["user/setPoint"](obj);

        this.user.points += 5;
        this.user.totalPoints += 5;
        this.points.push(obj);
        this.isLoading = false;
      } else if (
        this.optionAddPoint ===
        "Asistencia Webinar ¿Alimentar gatos es cosa de locos?"
      ) {
        const obj = {
          category: this.optionAddPoint,
          companyId: this.user.companyId,
          points: 10,
          period: this.currentPeriod,
          ref: this.optionAddPoint,
          timeStamp: Date.now(),
          uid: this.user.uid,
          year: "2022",
        };

        await this["user/setPoint"](obj);

        this.user.points += 10;
        this.user.totalPoints += 10;
        this.points.push(obj);
        this.isLoading = false;
      } else if (this.optionAddPoint === "Reto 2") {
        const challenge = this.company.challengeTwo.filter(
          (challenge) => challenge.points === this.selectedChallengeTwo
        );
        const obj = {
          category: this.optionAddPoint,
          companyId: this.user.companyId,
          points: this.selectedChallengeTwo,
          period: this.currentPeriod,
          ref: `Más de ${challenge[0].money} - ${challenge[0].points} puntos`,
          timeStamp: Date.now(),
          uid: this.user.uid,
          year: "2022",
        };

        await this["user/setPoint"](obj);

        this.user.points += this.selectedChallengeTwo;
        this.user.totalPoints += this.selectedChallengeTwo;
        this.points.push(obj);
        this.optionAddPoint = "";
        this.selectedChallengeTwo = "";
        this.isLoading = false;
      }
    },

    async setChallengePoint(challenge) {
      this.isLoading = true;
      const obj = {
        category: `Reto ${challenge.challengeId}`,
        companyId: this.user.companyId,
        points: challenge.points,
        period: this.currentPeriod,
        ref: `Reto ${challenge.challengeId}`,
        timeStamp: Date.now(),
        uid: this.user.uid,
      };

      await this["user/setChallengePoint"]({ obj, challenge });

      const index = this.challenges.indexOf(challenge);
      this.challenges.splice(index, 1);
      this.user.points += challenge.points;
      this.user.totalPoints += challenge.points;
      this.points.push(obj);
      this.isLoading = false;
    },

    async rejectChallengePoint(challenge) {
      this.isLoading = true;
      await this["user/rejectChallengePoint"](challenge.id);
      const index = this.challenges.indexOf(challenge);
      this.challenges.splice(index, 1);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  font-family: "Nunito";
}

input {
  border: 1px solid rgb(169, 169, 169);
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
}

select {
  border: 1px solid rgb(169, 169, 169);
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;

  option {
    text-align: center;
  }
}

textarea {
  border: 1px solid rgb(169, 169, 169);
  border-radius: 0.5rem;
  padding: 1rem 1rem;
}
</style>
